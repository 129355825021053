'use strict';

import { isUnsupportedBrowser } from './util';
import { browserWarningTemplate } from './templates';

if (isUnsupportedBrowser()) {
    const { body } = document;
    const warning = document.createElement('div');
    warning.className = 'browser-warning';
    warning.innerHTML = browserWarningTemplate;
    body.insertBefore(warning, body.firstChild);
}
