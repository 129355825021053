module.exports = {
    breakpoints: {
        xs: 0,
        sm: 576,
        md: 768,
        lg: 1024,
        xl: 1362
    },

    // Stores
    PREFERRED_STORE_ID: 'PREFERRED_STORE_ID',

    // Constant values
    EMPTY_STRING: '',

    // DOM Class
    HIDDEN_CLASS: 'd-none',
    INVALID_CLASS: 'is-invalid',
    INVISIBLE_CLASS: 'invisible',
    NO_SCROLL_CLASS: 'no-scroll',
    IS_STICKY_CLASS: 'is-sticky',
    SELECTED_CLASS: 'selected',

    // Product status
    PREORDER_STATUS: 'PREORDER',
    IN_STOCK_STATUS: 'IN_STOCK',
    NOT_AVAILABLE_STATUS: 'NOT_AVAILABLE',

    // Custom DOM events

    // Control keys

    // Event KeyCodes
    KEYCODE_TAB: 9,
    KEYCODE_ESCAPE: 27,
    KEYCODE_UP: 38,
    KEYCODE_DOWN: 40,
    KEYCODE_ENTER: 13,
    EVENT_KEY_ENTER: 'Enter',

    // Search types
    SEARCH_TYPE: 'searchType',
    SEARCH_SUBMIT: 'search submit',
    SEARCH_SUGGESTIONS: 'search suggestions',
    POPULAR_SEARCHES: 'popular searches'
};
